import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import BusinessServiceSidebar from "../components/Repeating/BusinessServiceSidebar";
import BusinessServiceSlider from "../components/Repeating/BusinessServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Outsourced Accounting & Bookkeeping Services | DC, MD & VA"
        description="Stop stressing over your books. Instead, hire DeBlanc + Murphy for your outsourced accounting and bookkeeping needs. Our DC CPA firm will handle it for you."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Outsourced Accounting & Bookkeeping Services</h1>
            <p className="mb-0 md:text-xl">
              Whether you’re operating a start-up, growing your business, or
              running a mature corporation, we have the expertise to handle your
              books. Serving the greater Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>We Can Take The Pressure Off</h2>
                <p>
                  Stop stressing over piles of paper, late payments, missing
                  invoices, and the frustration of not knowing your business’s
                  current financial standing.
                </p>
                <p>
                  Our qualified business accountants can handle your day-to-day
                  bookkeeping and accounting—from payroll to preparing your
                  financial statements. Our services include:
                </p>

                <ul className="styled-list-plus mb-6">
                  <li>Create chart of accounts for new business</li>
                  <li>Clean up the books for existing business</li>
                  <li>Integrate financial accounts and third-party apps with accounting software for real-time data reporting</li>
                  <li>Record transactions</li>
                  <li>Monthly and year-end close with account reconciliations</li>
                  <li>Prepare and present accounting reports to management</li>
                  <li>Accounts payable/bill pay services</li>
                  <li>Employee expense management</li>
                  <li>Payroll services, including payroll tax filings</li>
                  <li>Sales and Use tax filings</li>
                  <li>Personal property tax filings</li>
                  <li>Unclaimed property filings</li>
                  <li>1099-MISC preparation for independent contractors</li>
                  <li>Fractional CFO and Controller service
                      </li>
                </ul>
              </div>
              <div className="mb-16">
                <h3>Client Advisory Services</h3>
                <p>Outsource your entire accounting department to our team of expert accountants and advisors. Let us focus on the accounting work so you can focus on operations.</p>
                <p><strong>We can be your bookkeeper.</strong> We can handle all the transactional work to record, reconcile, and report financial data.</p>
                <p><strong>We can be your Controller.</strong> We can prepare budgets, forecasts, and KPIs to convert accounting information into actional intelligence so you can make better decisions about your business.</p>
                <p><strong>We can be your CFO.</strong> We can work with owners and management to develop a strategic financial plan that helps you grow revenue, reduce costs, improve cash flow, and achieve your vision for your business.</p>
              </div>
              <div className="mb-16">
                <h3>The Advantages of Outsourcing Accounting & Bookkeeping</h3>
                <p>
                  Meaningful, well-organized financial records are the
                  foundation of a successful business. They help you:
                </p>

                <ul className="styled-list-plus mb-6">
                  <li>Effectively manage your budget</li>
                  <li>Ensure your daily business operations run efficiently</li>
                  <li>
                    Spotlight where money is being wasted so you can make
                    improvements
                  </li>
                  <li>Save on costs associated with hiring an employee</li>
                  <li>Free up time so you can focus on your business</li>
                </ul>
              </div>

              <div>
                <h3>Businesses We Work With</h3>
                <p>
                  We’re a full-service CPA firm that works with clients big and
                  small to provide accurate, timely outsourced bookkeeping and
                  accounting services. We have experience handling the books
                  for:
                </p>
                <ul className="styled-list-plus mb-6">
                  <li>Sole Proprietorships</li>
                  <li>Limited Liability Corporations</li>
                  <li>Corporations</li>
                  <li>Partnerships</li>
                  <li>Non-Profits</li>
                  <li>Start-ups</li>
                </ul>

                <ButtonSolid modal="modal-contact" text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <BusinessServiceSidebar activeService={2} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <BusinessServiceSlider hideService={2} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: {
        eq: "2.5 Outsourced Accounting _ Bookkeeping/1.0 Hero.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
